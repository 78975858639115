import React from "react"
import { Box, Heading, Button } from "theme-ui"
import { Link } from "gatsby"

const Body = ({ body }) => {
  return <Box dangerouslySetInnerHTML={{ __html: body }} />
}

export default function TextContent({ title, subtitle, body, links }) {
  return (
    <Box sx={{ py: "2rem" }}>
      {title ? (
        <Heading
          as="h2"
          sx={{
            fontSize: ["2rem", "2.25rem", "2.75rem"],
            fontWeight: "bold",
            textTransform: "uppercase",
            color: "inherit",
            textAlign: "inherit",
            lineHeight: "1.5",
            mb: "1rem",
          }}
          className="title"
        >
          {title}
        </Heading>
      ) : null}

      {subtitle ? (
        <Heading
          as="h3"
          sx={{
            fontSize: ["1.5rem", "1.75rem", "2rem"],
            fontWeight: "bold",
            opacity: "0.7",
            textTransform: "uppercase",
            color: "inherit",
            textAlign: "inherit",
            mb: "1rem",
          }}
          className="subtitle"
        >
          {subtitle}
        </Heading>
      ) : null}

      {body ? <Body body={body} /> : null}

      {links &&
        links.map((link, index) => (
          <Link
            key={index}
            to={link.url}
            style={{ cursor: "pointer" }}
            target="_blank"
            rel="noopener"
          >
            <Button
              sx={{
                bg: "white",
                color: "#01567D",
                fontWeight: "bold",
                mt: 2,
                cursor: "pointer",
                "&:hover": {
                  bg: "white",
                  color: "black",
                },
              }}
            >
              {link.title}
            </Button>
          </Link>
        ))}
    </Box>
  )
}
