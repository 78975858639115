import { useStaticQuery, graphql } from "gatsby"

const useStories = () => {
  const query = useStaticQuery(graphql`
    query StoriesList {
      allGeneral {
        edges {
          node {
            articleType
            author
            available
            bodyHTML
            body
            bodyPlainText
            createdAt
            children {
              id
            }
            description
            flags
            id
            name
            readingTime
            readingTimeText
            subtitle
            tags
            title
            media {
              cloudinaryId
              description
              name
            }
            ctaLinks {
              title
              url
            }
          }
        }
      }
    }
  `)

  const data = query.allGeneral.edges.map(({ node }) => node)

  const getStoryById = id => {
    return data.find(story => id === story.id)
  }

  const getStoryByTag = tag => {
    return data.find(story => story?.tags?.includes(tag))
  }

  const getStoriesByTag = tag => {
    return data.filter(story => story?.tags?.includes(tag))
  }

  const getStoriesByName = name => {
    return data.filter(story => story?.name === name)
  }

  return {
    getStoryById,
    getStoryByTag,
    getStoriesByTag,
    getStoriesByName,
    stories: data,
  }
}

export default useStories
