import React from "react"
import checkIfExternalLink from "../../helpers/checkIfExternalLink"
import ExternalLink from "./ExternalLink"
import InternalLink from "./InternalLink"

export default function LinkSelector(props) {
  const { variant, ctaName, ctaLink, ctaClassName, style, sx, index } = props

  return (
    <>
      {checkIfExternalLink(ctaLink) ? (
        <ExternalLink
          ctaClassName={ctaClassName}
          ctaName={ctaName}
          ctaLink={ctaLink}
          style={style}
          sx={sx}
          index={index}
        />
      ) : (
        <InternalLink
          ctaClassName={ctaClassName}
          variant={`${variant}`}
          ctaName={ctaName}
          ctaLink={ctaLink}
          style={style}
          sx={sx}
          index={index}
        />
      )}
    </>
  )
}
