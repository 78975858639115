import React, { useEffect } from "react"
import { Box } from "theme-ui"
import { Link } from "gatsby"

export default function InternalLink({
  ctaClassName,
  ctaName,
  ctaLink,
  style,
  sx,
  index,
}) {
  const isEvenIndexButton = index ? index % 2 === 0 : true // if index has been set there we have added functionaility for multiple buttons and need changing styling for buttons

  return (
    <Link className={`gatsby-link`} to={ctaLink} style={style}>
      <Box
        as="span"
        variant={`buttons.${isEvenIndexButton ? "primary" : "secondary"}`}
        className={`${ctaClassName ? ctaClassName : ""} ${
          isEvenIndexButton ? "primaryButton" : "secondaryButton"
        } `}
        sx={{ display: "flex", scrollBehavior: "smooth", ...sx }}
      >
        {ctaName}
      </Box>
    </Link>
  )
}
