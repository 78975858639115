import React from "react"
import LinkSelector from "../Links/LinkSelector"
import { Flex } from "theme-ui"

export default function MultipleLinksSelector({ links, sx }) {
  return (
    <Flex
      variant="buttons.container"
      className="linksContainer"
      sx={{
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "inherit",
        alignItems: "inherit",
        mt: 2,
        ...sx,
      }}
    >
      {links?.length > 0
        ? links.map(({ title, url }, index) => {
            return (
              <React.Fragment key={title + url + index}>
                {title && url ? (
                  <LinkSelector
                    ctaName={title}
                    ctaLink={url}
                    style={{
                      marginRight: links.length > 1 ? 16 : 0,
                      marginBottom: 16,
                    }}
                    index={index}
                  />
                ) : null}
              </React.Fragment>
            )
          })
        : null}
    </Flex>
  )
}
