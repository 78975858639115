import React from "react"
import { Link, Box } from "@theme-ui/components"

export default function ExternalLink({
  ctaClassName,
  ctaName,
  ctaLink,
  style,
  sx,
  index,
}) {
  const isEvenIndexButton = index ? index % 2 === 0 : true
  return (
    <Link href={ctaLink} target={"_blank"} rel="noreferrer" style={style}>
      <Box
        as="span"
        variant={`buttons.${isEvenIndexButton ? "primary" : "secondary"}`}
        className={`${ctaClassName ? ctaClassName : ""} ${
          isEvenIndexButton ? "primaryButton" : "secondaryButton"
        } `}
        sx={{ display: "flex", ...sx }}
      >
        {ctaName}
      </Box>
    </Link>
  )
}
