import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import { Box } from "theme-ui"

import useStories from "../hooks/useStories"
import TextContent from "../components/TextContent"
import MultipleLinksSelector from "../components/Links/MultipleLinksSelector"

const MugClub = () => {
  // const bulletPoints = [
  //   "S.W.A.G – Dockside mug club t-shirt, Mug Club Card",
  //   "First Beer on da house upon enrollment",
  //   "Mug Club Night-Thursdays $1 off Dockside Beers & Pizza",
  //   "Das Boot with the purchase of a beer on Birthday for Mug Club Members",
  //   "15% discount for merchandise for Mug Club Members",
  //   "Annual Winter Mug Club Brew. Help design and brew a beer for the winter season",
  //   "2 Mug Club Parties. Summer Party & Holiday Party",
  // ]

  const bulletPoints = [
    "Dockside Mug & Mug Club Card (sticker for renewal members)",
    "Mug Club Passport-Monthly beer list (6 beers) to be completed monthly",
    "Complete all 12 months, and get a change to write and brew your own beer with the brew staff.",
    "Burger and Dockside beer for $15 on Mondays",
    "Mug Club Night-Thursdays 15% off Mug Club (Member Only) bill 15% discount for merchandise",
    "2 Mug Club Parties. Summer Party & Mug Club Christmas Tree Lighting",
    "Free Das Boot on your Birthday",
    "Early access to can releases",
  ]

  const { getStoryByTag } = useStories()

  const mugClub = getStoryByTag("mug-club1")

  return (
    <Layout pageTitle="Mug Club">
      <Page>
        <Box
          sx={{
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            ul: {
              listStyle: "unset",
              textAlign: "left",
              padding: "1rem",
              columnGap: "3rem",
              margin: "auto",
              maxWidth: "900px",
              columns: ["1!important", "1!important", "2 !important"],
              "-webkit-columns": ["1!important", "1!important", "2 !important"],
              "-moz-columns": ["1!important", "1!important", "2 !important"],
              li: {
                fontSize: "1.2rem",
                mb: "0.75rem",
                fontWeight: "200",
              },
            },
          }}
        >
          <TextContent
            title={mugClub?.title}
            subtitle={mugClub?.subtitle}
            body={mugClub?.body}
          />
          <MultipleLinksSelector
            links={mugClub?.ctaLinks}
            sx={{
              ".primaryButton": {
                variant: "buttons.secondary",
              },
            }}
          />
        </Box>
      </Page>
    </Layout>
  )
}

const Page = styled.div`
  padding: 2rem 1.5rem;
  background: ${({ theme }) => theme.primary};
`

export default MugClub
